import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiStore } from '../../../../core/store/ui.store';
import { SpinnerComponent } from '../../loaders/spinner/spinner.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-saving-button',
  standalone: true,
  imports: [SpinnerComponent, TranslateModule],
  templateUrl: './saving-button.component.html',
  styleUrl: './saving-button.component.scss',
})
export class SavingButtonComponent {
  @Input() label: string = 'Save';
  @Input() savingLabel: string = '';
  @Input() isValid: boolean = true;
  @Output() saveRequest = new EventEmitter<void>();

  constructor(public uiStore: UiStore) {}

  requestSaveItem(): void {
    this.saveRequest.emit();
  }
}
