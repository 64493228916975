@if (!uiStore.saving) {
  <button type="submit" (click)="requestSaveItem()" class="btn btn-primary btn-theme me-2 " [disabled]="!isValid">
    {{ label }}
  </button>
}@else if (uiStore.saving) {
  <button class="btn btn-primary btn-theme me-2" type="button" disabled>
    <app-spinner></app-spinner>
    {{ savingLabel | translate}}
  </button>
}
